import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { playerWinsAndLoses } from '../api';

import styled from 'styled-components';

const columns = [
	{
		name: 'მოთამაშე',
		selector: row => row.username,
		sortable: true,
	},
	{
		name: 'თამაშები',
		selector: row => row.count,
		sortable: true,
	},
	{
		name: 'მოგება',
		selector: row => row.wins,
		sortable: true,

	},
	{
		name: 'წაგება',
		selector: row => row.loses,
		sortable: true,

	},
	{
		name: 'კეთილებით მოგება',
		selector: row => row.goodWins,
		sortable: true,

	},
	{
		name: 'კეთილებით წაგება',
		selector: row => row.goodLoses,
		sortable: true,

	},
	{
		name: 'ბოროტებით მოგება',
		selector: row => row.evilWins,
		sortable: true,
	},
	{
		name: 'ბოროტებით წაგება',
		selector: row => row.evilLoses,
		sortable: true,
	},
	{
		name: "მოგების სიხშირე",
		selector: row => row.winRate,
		sortable: true,
		format: row => `${row.winRate}%`
	}
];

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled.button`
	border-style: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: rgb(41, 121, 255);
    border: none;
    color: white;
    padding: 8px 32px;
    text-decoration: none;
    font-size: 16px;
	cursor: pointer;

`;

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	&>div {
		display: flex;
	}
`;

const FilterComponent = ({ title, filterText, onFilter, onClear }) => (
	<Container>
		<h3>{title}</h3>
		<div>
			<TextField
				id="search"
				type="text"
				placeholder="Filter By Name"
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<ClearButton type="button" onClick={onClear}>
				X
			</ClearButton>
		</div>
	</Container>
);

export default function PlayerWinsAndLoses() {
	const [filterText, setFilterText] = useState('');
	const [data, setData] = useState([]);
	const filteredItems = data.filter(item => item.username && item.username.toLowerCase().includes(filterText.toLowerCase()));


	useEffect(() => {
		playerWinsAndLoses().then(res => {
			setData(res.data);
		});
	}, []);

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setFilterText('');
			}
		};
		return <FilterComponent title="მოგებების სტატისტიკა" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText]);

	return (
		<div>
			<DataTable
				columns={columns}
				data={filteredItems}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				defaultSortFieldId={3}
				defaultSortAsc={false}
				pagination
				dense
			/>
		</div>
	);
}
