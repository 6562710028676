import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getMostPoisonedPlayers, getMostPopularRoles } from '../api';

import styled from 'styled-components';

const initialColumns = [
	{
		name: 'Character',
		selector: row => row.name,
		sortable: true,
	},
	{
		name: 'ყველა სკრიპტი',
		selector: row => row.exceptHomebrew,
		sortable: true,
	},
	{
		name: 'Trouble Brewing',
		selector: row => row.tb || 0,
		sortable: true,
	},
	{
		name: 'Bad Moon Rising',
		selector: row => row.bmr || 0,
		sortable: true,

	},
	{
		name: 'Sects and Violets',
		selector: row => row.snv || 0,
		sortable: true,

	},
];

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled.button`
	border-style: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: rgb(41, 121, 255);
    border: none;
    color: white;
    padding: 8px 32px;
    text-decoration: none;
    font-size: 16px;
	cursor: pointer;

`;

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	&>div {
		display: flex;
	}
`;

const FilterComponent = ({ title, filterText, onFilter, onClear }) => (
	<Container>
		<h3>{title}</h3>
		<div>
			<TextField
				id="search"
				type="text"
				placeholder="Filter By Name"
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<ClearButton type="button" onClick={onClear}>
				X
			</ClearButton>
		</div>
	</Container>
);

export default function MostPopularRoles() {
	const [filterText, setFilterText] = useState('');
	const [columns, setColumns] = useState(initialColumns);
	const [data, setData] = useState([]);
	const filteredItems = data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));


	useEffect(() => {
		getMostPopularRoles().then(res => {
			setData(res.data.roles);
			const newColumns = [...initialColumns];
			newColumns[1] = {
				...newColumns[1],
				name: `${newColumns[1].name} (${res.data.exceptHomebrew})`,
			};
			newColumns[2] = {
				...newColumns[2],
				name: `${newColumns[2].name} (${res.data.tb})`,
			};
			newColumns[3] = {
				...newColumns[3],
				name: `${newColumns[3].name} (${res.data.bmr})`,
			};
			newColumns[4] = {
				...newColumns[4],
				name: `${newColumns[4].name} (${res.data.snv})`,
			};

			setColumns(newColumns);
		});
	}, []);

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setFilterText('');
			}
		};
		return <FilterComponent title="ყველაზე პოპულარული პერსონაჟები" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText]);

	return (
		<div>
			<DataTable
				columns={columns}
				data={filteredItems}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				defaultSortFieldId={2}
				defaultSortAsc={false}
				pagination
				dense
			/>
		</div>
	);
}
