const apiUrl = process.env.REACT_APP_API_URL;

export const getMostPoisonedPlayers = async () => {
	const response = await fetch(`${apiUrl}stats/most-poisoned-players`);
	const data = await response.json();
	return data;
}

export const playerWinsAndLoses = async () => {
	const response = await fetch(`${apiUrl}stats/player-wins-and-loses`);
	const data = await response.json();
	return data;
}

export const getMostPopularRoles = async () => {
	const response = await fetch(`${apiUrl}stats/most-popular-roles`);
	const data = await response.json();
	return data;
}

export const getMostNominatedPlayers = async () => {
	const response = await fetch(`${apiUrl}stats/most-nominated-players`);
	const data = await response.json();
	return data;
}
