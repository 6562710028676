import { useEffect, useRef, useState } from 'react';
import './App.css';

import lifeCircle from './images/circle.webp';
import hourArrow from './images/arrow-hour.webp';
import minuteArrow from './images/arrow-minute.webp';
import secondArrow from './images/arrow-second.webp';
import facebookLogo from './images/facebook-logo.png';
import youtubeLogo from './images/youtube-logo.png';
import tiktokLogo from './images/tiktok-logo.png';
import instagramLogo from './images/instagram-logo.png';
import discordLogo from './images/discord-logo.png';
import fbGroupLogo from './images/fb-group-logo.png';
import rulesLogo from './images/rules.png';
import { Link } from 'react-router-dom';

const CIRCLE_COUNT = 8;
const circleLogos = [youtubeLogo, discordLogo, fbGroupLogo, facebookLogo, instagramLogo, tiktokLogo, rulesLogo];
const circleHrefs = [
	'https://www.youtube.com/@MastermindGeo',
	'https://discord.gg/V2nDtAFbd4',
	'https://www.facebook.com/groups/botcgeorgia',
	'https://www.facebook.com/mastermindgeorgia',
	'https://www.instagram.com/mastermind.geo/',
	'https://www.tiktok.com/@mastermindgeo',
	'https://www.youtube.com/watch?v=upd6m6cste0&list=PL9qOkeFMGsi_uOYexBKWnhjVmg5GGh_jN'
];

const eventInfo = {
	date: '7 იანვარი, 20:00 - 23:59',
	url: 'https://online.bloodontheclocktower.com/join/mastermind',
	pin: 'mastermind'
};

function App() {
	let [dateTime, setDateTime] = useState(new Date());
	let initialDateRef = useRef(new Date());
	let [secondsPassed, setSecondsPassed] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setDateTime(new Date());
			setSecondsPassed(secondsPassed + 1);
		}, 1000);
	}, [dateTime, secondsPassed]);

	let secondArrowDeg = 360 / 60 * (initialDateRef.current.getSeconds() + secondsPassed);
	let minuteArrowDeg = 360 / 60 * (initialDateRef.current.getMinutes() + secondsPassed / 60);
	let hourArrowDeg = 360 / 12 * (initialDateRef.current.getHours() + secondsPassed / 3600);

	let scale = window.innerWidth < 1024 ? 0.8 : 1;
	let translateY = window.innerWidth < 1024 ? '-30px' : '0';


	return (
		<div className="App">
			<div className='night-backdrop'></div>
			<div className='stats'>
				<Link to='/stats' className='stats__link'>სტატისტიკა</Link>
			</div>
			<ul className='circle'>
				{
					[...Array(CIRCLE_COUNT)].map((_, i) => (
						<li className='circle__item' style={{ transform: `scale(${scale}) rotate(${360 / CIRCLE_COUNT * i}deg) translate(-50%, ${translateY})` }}>
							<img src={lifeCircle} className="life-circle" alt="logo" />
							{circleLogos[i] && <a
								href={circleHrefs[i] || ''}
								target="_blank"
								rel="noreferrer"
								style={{ transform: `translate(-50%, -50%) rotate(${-360 / CIRCLE_COUNT * i}deg)` }}
							>
								<img
									src={circleLogos[i]}
									className="circle-logo"
									alt="logo"
								/>
							</a>}
						</li>
					))
				}
				<div className='arrows'>
					<img src={hourArrow} className="hour-arrow" alt="logo" style={{ transform: `translate(-50%, 0) rotate(${hourArrowDeg}deg)` }} />
					<img src={minuteArrow} className="minute-arrow" alt="logo" style={{ transform: `translate(-50%, 0) rotate(${minuteArrowDeg}deg)` }} />
					<img src={secondArrow} className="second-arrow" alt="logo" style={{ transform: `translate(-50%, 0) rotate(${secondArrowDeg}deg)` }} />
				</div>
				<li style={{
					width: '10px',
					height: '10px',
					background: 'red',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					margin: 0,
					padding: 0,
					borderRadius: '50%'
				}}></li>
			</ul>
			<div className='copyright'>
				<p>This is the website for Blood on the Clocktower's Georgian community </p>
				<p>The game is owned by <a href="https://bloodontheclocktower.com/">The Pandemonium Institute</a></p>
				<p>© 2024 Mastermind Georgia</p>
			</div>
		</div>
	);
}

export default App;
