import React from 'react';
import MostPoisonedPlayers from './MostPoisonedPlayers';
import PlayerWinsAndLoses from './PlayerWinsAndLoses';
import styled from 'styled-components';
import MostPopularRoles from './MostPopularRoles';
import MostNominatedPlayers from './MostNominatedPlayers';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export default function Stats() {
	return (
		<Container>
			<MostPoisonedPlayers />
			<MostNominatedPlayers />
			<PlayerWinsAndLoses />
			<MostPopularRoles />
		</Container>
	);
}

